import React from 'react'
import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby';

export default function Services() {

  const picDim = '35%';

  return (
    <Layout>
      <section>
        <div className='container'>
          <div className='row d-flex justify-content-center'>
            <div className="col-sm-12 col-lg-8 mb-5">

              <h4>Front Door Leaded Decorative Glass Repair (Single Glazed)</h4>

              <p>
                From a simple repair as a small suncatcher hanging in your window,  or Tiffany lamp shades knocked over by the cat or dog or a rambunctious little one. Or a surprise problem with your front door glass, it might have fallen prey to a flying rock from lawn equipment or kids rough housing or kicking and throwing balls around that may accidentally fly into the glass.
              </p>
              <p>
                No need to replace the whole door, in most cases, this front door glass can be repaired on-site in a couple hours time, saving you potentially thousands. In any case I can provide the solution to get it back as it was without the added expense.
              </p>
            </div>

          </div>

          <div className='row mb-5 d-flex justify-content-center'>
            <div className="col-sm-12 col-lg-4 mb-3">

              <div className='d-flex justify-content-center'>
                {/* <a target='_blank' href='https://beheben.net/wordpress/wp-content/uploads/2022/07/IMG695-768x1024.jpg'> */}
                <a target='_blank' href='https://beheben.net/images/IMG695-768x1024.jpg'>
                  <StaticImage
                    src='../images/IMG695-768x1024.jpg'
                    width={518}
                    alt='logo'
                    placeholder='tracedSVG'
                    quality='40'
                  />
                </a>
              </div>

              <small className='d-flex justify-content-center'>(Click to Enlarge)</small>

            </div>
            <div className="col-sm-12 col-lg-4">

              <div className='d-flex justify-content-center'>
                <a target='_blank' href='https://beheben.net/images/Beveled_Oval-1.jpg'>
                  <StaticImage
                    src='../images/Beveled_Oval-1.jpg'
                    width={518}
                    alt='logo'
                    placeholder='tracedSVG'
                    quality='40'
                  />
                </a>

              </div>

              <small className='d-flex justify-content-center'>(Click to Enlarge)</small>

            </div>

          </div>

        </div>
      </section>
    </Layout>
  )
}